<template>
    <div class="authorization" v-if="translates && translates[langUrl]">
        <div class="authorization__form" v-if="translates && translates[langUrl]">
            <div class="authorization__form__top">
                <div class="authorization__form__top__logo" v-if="isDesktop"></div>
                <div class="authorization__form__top__title">{{ pageTitle }}</div>
            </div>
            <template v-if="step === 'phoneTextField'">
                <div class="authorization__form__input">
                    <ValidationObserver ref="validator_1" slim>
                        <ValidationProvider v-slot="{ errors }" rules="required">
                            <BasePhoneNumberInput
                                v-model="phone"
                                :error-messages="errors[0]"
                                @update="number($event)"
                                @keyup.enter="login"
                            />
                        </ValidationProvider>
                    </ValidationObserver>
                    <BaseButton 
                        class="button-fill" 
                        style="width: 100%"
                        @click="login">{{ translates[langUrl].button_next[lang] }}</BaseButton>
                </div>
            </template>
            <template v-if="step === 'phoneConfirm'">
                <div class="authorization__form__text">{{ translates[langUrl].nuber_part_1[lang] }} {{ phoneWithCode }} {{ translates[langUrl].nuber_part_2[lang] }}</div>
                <div class="authorization__form__input">
                    <ValidationObserver ref="validator_2" slim>
                        <ValidationProvider v-slot="{ errors }" rules="required|code">
                            <LabelTextField
                                v-model="code"
                                pattern="\d*"
                                :label="translates[langUrl].label_code[lang]"
                                counter="4"
                                :error-messages="errors[0]"
                                @keyup.enter="confirmPhone"
                                autocomplete="off"
                            />
                        </ValidationProvider>
                        <div v-if="secondsLeft" class="authorization__form__send-code">{{ translates[langUrl].button_codeRepeat_part_1[lang] }} <span class="authorization__form__send-code__timer">{{ translates[langUrl].button_codeRepeat_part_2[lang] }} {{ prettyCounter }}</span></div>
                        <div v-else class="authorization__form__send-code_active" @click="phoneRepeatSubmit">{{ translates[langUrl].button_codeRepeat_part_1[lang] }}</div>
                    </ValidationObserver>
                    <BaseButton
                        style="width: 100%"
                        class="button-fill"
                        @click="confirmPhone">{{ translates[langUrl].button_next[lang] }}</BaseButton>
                </div>
            </template>
            <template v-if="step === 'emailTextField'">
                <div class="authorization__form__input">
                    <ValidationObserver ref="validator_3" slim>
                        <ValidationProvider v-slot="{ errors }" rules="required|email">
                            <LabelTextField
                                :label="translates[langUrl].label_email[lang]"
                                :error-messages="errors[0]"
                                v-model="email"
                                @keyup.enter="saveEmail"
                            />
                        </ValidationProvider>
                    </ValidationObserver>
                    <BaseButton
                        class="button-fill blogger-registration__form__button"
                        @click="saveEmail">{{ translates[langUrl].button_next[lang] }}</BaseButton>
                </div>
            </template>
            <template v-if="step === 'emailConfirm'">
                <div class="authorization__form__text">{{ translates[langUrl].email_part_1[lang] }} {{ email }} {{ translates[langUrl].email_part_2[lang] }}</div>
                <div class="authorization__form__input">
                    <ValidationObserver ref="validator_4" slim>
                        <ValidationProvider v-slot="{ errors }" rules="required|code">
                            <LabelTextField
                                v-model="emailCode"
                                pattern="\d*"
                                :label="translates[langUrl].label_emailCode[lang]"
                                counter="4"
                                @keyup.enter="confirmEmail"
                                :error-messages="errors[0]"
                            />
                        </ValidationProvider>
                        <div v-if="secondsLeft" class="authorization__form__send-code">{{ translates[langUrl].button_codeRepeat_part_1[lang] }} <span class="authorization__form__send-code__timer">{{ translates[langUrl].button_codeRepeat_part_2[lang] }} {{ prettyCounter }}</span></div>
                        <div v-else class="authorization__form__send-code_active" @click="emailRepeatSubmit">{{ translates[langUrl].button_codeRepeat_part_1[lang] }}</div>
                    </ValidationObserver>
                    <BaseButton
                        style="width: 100%"
                        class="button-fill"
                        @click="confirmEmail">{{ translates[langUrl].button_next[lang] }}</BaseButton>
                </div>
            </template>
            <template v-if="step === 'userInfo'">
                <div class="authorization__form__input">
                    <ValidationObserver ref="validator_5" slim>
                        <ValidationProvider v-slot="{ errors }" rules="required">
                            <LabelTextField
                                :label="translates[langUrl].firstname[lang]"
                                :error-messages="errors[0]"
                                v-model="firstname"
                            />
                        </ValidationProvider>
                        <ValidationProvider v-slot="{ errors }" rules="required">
                            <LabelTextField
                                :label="translates[langUrl].lastname[lang]"
                                :error-messages="errors[0]"
                                v-model="lastname"
                                @keyup.enter="saveUser"
                            />
                        </ValidationProvider>
                        <!-- <ValidationProvider v-slot="{ errors }" rules="required|social">
                            <LabelTextField
                                :class="{ 'error--text': serverErrors.social }"
                                :details="serverErrors.social"
                                v-model="social"
                                :label="translates[langUrl].social[lang]"
                                :error-messages="errors[0] || serverErrors.social"
                                :counter="100"
                                @keyup.enter="saveUser"
                            />
                        </ValidationProvider> -->
                    </ValidationObserver>
                    <BaseButton
                        class="button-fill blogger-registration__form__button"
                        @click="saveUser">{{ translates[langUrl].button_next[lang] }}</BaseButton>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { isMobile } from '@/helpers';

    import BaseButton from '../../components/common/BaseButton';
    import LabelTextField from '../../components/common/LabelTextField';
    import BasePhoneNumberInput from '../../components/common/BasePhoneInput.vue';

    export default {
        name: 'Registration',
        metaInfo: {
            title: 'Регистрация'
        },
        components: {
            ValidationProvider,
            ValidationObserver,
            BaseButton,
            LabelTextField,
            BasePhoneNumberInput
        },
        data: () => ({
            isMobile,
            isDesktop: false,
            phoneCode : null,
            phone: '',
            phoneWithCode: '',
            code: '',
            email: '',
            emailCode: '',
            firstname: '',
            lastname: '',
            social: '',
            step: 'phoneTextField',
            steps: [
                'phoneTextField',
                'phoneConfirm',
                'emailTextField',
                'emailConfirm',
                'userInfo'
            ],
            shown: false,
            secondsLeft: 0,
            intervalId: undefined,
            langUrl: 'authorization'
        }),
        computed: {
            ...mapState('auth', ['user']),
            ...mapState('settings', ['lang']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            prettyCounter() {
                if (this.secondsLeft < 1) {
                    return '';
                }
                const minutes = Math.floor(this.secondsLeft / 60);
                const seconds = this.secondsLeft - minutes * 60;

                return `${minutes}:${seconds >= 10 ? seconds : `0${seconds}`}`;
            },
            pageTitle() {
                return {
                    'phoneTextField': this.translates ? this.translates[this.langUrl].pageTitle_phoneTextField[this.lang] : '',
                    'phoneConfirm': this.translates ? this.translates[this.langUrl].pageTitle_phoneConfirm[this.lang] : '',
                    'emailTextField': this.translates ? this.translates[this.langUrl].pageTitle_emailTextField[this.lang] : '',
                    'emailConfirm': this.translates ? this.translates[this.langUrl].pageTitle_emailConfirm[this.lang] : '',
                    'userInfo': this.translates ? this.translates[this.langUrl].pageTitle_userInfo[this.lang] : ''
                }[this.step];
            }
        },
        async mounted() {
            this.$root.$emit('preloaderShow');
            await store.dispatch('auth/logout');
            store.commit('trips/CLEAR_ENTITIES');
            store.commit('events/CLEAR_ALL');
            this.onResize();
            this.$root.$once('authorized', async () => {
                if(this.user.blogger) {
                    await this.$router.push({ name: 'blogger-audience' });
                } else {
                    await this.$router.push({ name: 'home' });
                }                    
            });
            this.$root.$emit('preloaderHide');
        },
        methods: {
            onResize() {
                this.isDesktop = !isMobile();
            },
            countryCode() {
                return this.lang === 'en' ? "US" : "RU";
            },
            startTimer() {
                if (this.intervalId) {
                    clearInterval(this.intervalId);
                }
                this.secondsLeft = 60;
                this.intervalId = setInterval(() => {
                    if (this.secondsLeft > 0) {
                        this.secondsLeft -= 1;
                    } else {
                        clearInterval(this.intervalId);
                    }
                }, 1000);
            },
            async login() {
                const valid = await this.$refs['validator_1'].validate();
                if(valid) {
                    await store.dispatch('auth/login', { phone: this.phoneWithCode });
                    this.step = 'phoneConfirm';
                }
                await this.startTimer();
            },
            number(event) {
                this.phoneWithCode = event.formattedNumber
            },
            async phoneRepeatSubmit() {
                this.startTimer();
                await store.dispatch('auth/login', { phone: this.phoneWithCode });
            },
            async confirmPhone() {
                const valid = await this.$refs['validator_2'].validate();
                if(valid) {
                    await store.dispatch('auth/confirm', { code: this.code });
                    await store.dispatch('auth/fetch');
                    await store.dispatch('events/summary');
                    await store.dispatch('events/fetch', {});
                    if(!this.user.registrationComplete) {
                        if(!this.user.emailConfirmed) {
                            this.step = 'emailTextField';
                        } else {
                            this.step = 'userInfo';
                        }
                    } else {
                        this.$root.$emit('authorized');
                    }
                }
            },
            async saveEmail() {
                const valid = await this.$refs['validator_3'].validate();
                if(valid) {
                    await store.dispatch('auth/emailUpdate', { email: this.email });
                    await store.dispatch('auth/fetch');
                    this.step = 'emailConfirm';
                }
                await this.startTimer();
            },
            async emailRepeatSubmit() {
                this.startTimer();
                await store.dispatch('auth/emailUpdate', { email: this.email });
            },
            async confirmEmail() {
                const valid = await this.$refs['validator_4'].validate();
                if(valid) {
                    await store.dispatch('auth/emailConfirm', { code: this.emailCode });
                    await store.dispatch('auth/fetch');
                    this.step = 'userInfo';
                }
            },
            async saveUser() {
                const valid = await this.$refs['validator_5'].validate();
                if(valid) {
                    store.commit('auth/SET_USER', { ...this.user, firstname: this.firstname, lastname: this.lastname });
                    await store.dispatch('auth/save');
                    await store.dispatch('auth/fetch');
                    this.$root.$emit('authorized');
                }
            },
            async back() {
                if(this.steps.indexOf(this.step) > 0) {
                    this.step = this.steps[this.steps.indexOf(this.step) - 1];
                }
            },
            close() {
                this.shown = false;
                this.code = '';
                this.phone = '';
                this.step = this.steps[0];
            },
        }
    }
</script>

<style lang="scss">
.authorization {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &__form {
        width: 600px;
        padding: 60px;
        margin: 60px 0;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        background: #FFFFFF;
        box-shadow: 0px 4px 3px rgba(44, 47, 56, 0.01), 0px 4px 20px rgba(44, 47, 56, 0.08);
        border-radius: 24px;

        &__top {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-bottom: 32px;

            &__logo {
                height: 33px;
                width: 300px;
                background: url(../../assets/Main-logo.svg);
                background-repeat: no-repeat;
                background-size: contain;
                margin-bottom: 40px;
            }

            &__title {
                display: flex;
                flex-direction: column;
                width: 280px;
                font-weight: 600;
                font-size: 28px;
                line-height: 140%;
                letter-spacing: -0.02em;
                color: #273155;
            }
        }
        &__text {
            padding-top: 10px;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: -0.02em;
            opacity: 0.5;
            // padding-bottom: 10px;
        }
        &__input {
            margin-top: 10px;
            display: flex;
            flex-direction: column;
        }
        &__send-code {
            font-size: 12px;
            line-height: 15px;
            letter-spacing: -0.02em;
            color: #3A4256;
            margin: 7px auto 20px 10px;
            &__timer {
                opacity: 0.5;
            }
            &_active {
                font-size: 12px;
                line-height: 15px;
                letter-spacing: -0.02em;
                text-decoration: underline;
                // opacity: 0.5;
                color: #3A4256;
                margin: 7px auto 20px 10px;
                cursor: pointer;
            }
        }
        &__buttons {
            display: flex;
            flex-direction: column;
            gap: 15px
        }
    }
}

</style>
